import React from "react";
import { CollapseDropdown } from "../Dropdown";
const pdf = "resultAnalysis/computer/2ndsem.pdf";
export default function DeptEventAnalysis({ data }) {
    return (
        <>
            <h1 className="heading">Various Events</h1>
            {data.map((ra) => (
                <CollapseDropdown data={ra} />
            ))}
            <>			<table border="1px">
				<thead>
					<tr>

						<td>Name Of Event</td>
						<td>Event  Photo </td>
						<td>Details Of Event</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>1</td>
						<td>
							<img src="client/src/images/computer.jpg" width={100} height={100} alt="" />
						</td>
						<td>
							Shri. Narsingh Chetram Basena (Office
							Superintendent)
						</td>
						
					</tr>
					<tr>
						<td>2</td>
						<td>
							<img src="client/src/images/computer.jpg" width={100} height={100} alt="" />
						</td>
						<td>
							Shri. Narsingh Chetram Basena (Office
							Superintendent)
						</td>
						
					</tr>
					<tr>
						<td>3</td>
						<td>
							<img src="client/src/images/computer.jpg" width={100} height={100} alt="" />
						</td>
						<td>
							Shri. Narsingh Chetram Basena (Office
							Superintendent)
						</td>
						
					</tr>
					<tr>
						<td>4</td>
						<td>
							<img src="client/src/images/computer.jpg" width={100} height={100} alt="" />
						</td>
						<td>
							Shri. Narsingh Chetram Basena (Office
							Superintendent)
						</td>
						
					</tr>
					<tr>
						<td>5</td>
						<td>
							<img src="client/src/images/computer.jpg" width={100} height={100} alt="" />
						</td>
						<td>
							Shri. Narsingh Chetram Basena (Office
							Superintendent)
						</td>
						
					</tr>
				</tbody>
			</table>
		</>
        </>
    );
}
